import React from 'react';

interface Props {
  isVisible: boolean;
  position?: string;
}

const defaultProps = {
  position: 'fixed',
};

function Loader(props: Props) {
  const { isVisible, position } = props;
  if (!isVisible) {
    return null;
  }
  return (
    <div
      className={`${position || 'fixed'} left-0 top-0 ${
        position === 'absolute' ? 'h-full w-full' : 'h-screen w-screen'
      } center z-[999] bg-white opacity-50`}
    >
      <div className='absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2 transform '>
        <div className='h-10 w-10 animate-spin rounded-full border-4 border-solid border-gray-400 border-t-transparent border-t-transparent' />
      </div>
    </div>
  );
}

Loader.defaultProps = defaultProps;

export default Loader;